<template>
  <div>
    <b-tabs content-class="mt-3" v-model="tabIndex" small>
      <b-tab title="Draw AOI" active>
        <small class="">Please draw an AOI on the map.</small>
        <b-icon id="info-icon-draw" icon="info-circle" scale="0.8" shift-h="2" variant="info"></b-icon>
        <b-popover target="info-icon-draw" triggers="hover" placement="top">
          Please consider that the maximum supported AOI size is limited to <br/> 100.000 km².
        </b-popover>
        <div v-if="aoiSizeWarning">
          <b-alert show variant="warning" class="mt-2">
            <p>The area of the AOI is too large! Please change the size.</p>
            <p class="mb-0">Maximum area allowed: {{getAreaInKM2(this.aoiSizeMax)}} &#13218;</p>
            <p class="mb-1">Area of current AOI: {{getAreaInKM2(this.aoiSizeCurrentAOI)}} &#13218;</p>
          </b-alert>
        </div>
        <div class="mt-2">
          <div class="label-style">Geometry</div>
          <div class="mb-2">
            <div v-if="drawnAOICount==0">
              <b-icon
                icon="exclamation-triangle-fill"
                scale="1.2"
                variant="warning"
                class="mr-2"
              ></b-icon>
              <label class="aoi-info-label">No AOI added!</label>
            </div>
            <div v-else-if="drawnAOICount!=0">
              <!-- <b-icon
                icon="check-square-fill"
                scale="1.2"
                variant="success"
                class="mr-2"
              ></b-icon> -->
              <label class="aoi-info-label">AOI added!</label>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="Upload AOI">
        <small class="">Please upload a valid GeoJSON file from your local disc.</small>
        <b-icon id="info-icon-upload" icon="info-circle" scale="0.8" shift-h="2" variant="info"></b-icon>
        <b-popover target="info-icon-upload" triggers="hover" placement="top">
          Please provide a valid GeoJSON polygon. Be aware that multi-polygons are not supported and 
          the maximum supported AOI size is limited to <br/> 100.000 km².
        </b-popover>
        <div v-if="aoiSizeWarning">
          <b-alert show variant="warning" class="mt-2 mb-2">
            <p>The area of the AOI is too large! Please change the size.</p>
            <p class="mb-0">Maximum area allowed: {{getAreaInKM2(this.aoiSizeMax)}} &#13218;</p>
            <p class="mb-1">Area of current AOI: {{getAreaInKM2(this.aoiSizeCurrentAOI)}} &#13218;</p>
          </b-alert>
        </div>
        <div v-if="geoJSONWarning">
          <b-alert show variant="warning" class="mt-02">
            The uploaded file isn't a valid GeoJSON!
          </b-alert>
        </div>
        <b-form-group class="mt-1">
          <label class="label-style">GeoJSON File</label>
          <b-input-group>
            <b-form-file v-model="file" type="file" @change="uploadFile" ref="file" placeholder="Select file ..."></b-form-file>
          </b-input-group>
        </b-form-group>
        <div class="mt-2">
        <div class="label-style">Geometry</div>
          <div class="mb-2">
            <div v-if="!isAOIUploaded">
              <b-icon
                icon="exclamation-triangle-fill"
                scale="1.2"
                variant="warning"
                class="mr-2"
              ></b-icon>
                <label class="aoi-info-label">No AOI uploaded!</label>
            </div>
            <div v-else>
              <label class="aoi-info-label">AOI uploaded!</label>
              <b-icon
                id="trash-icon"
                shift-h="4"
                v-b-tooltip.hover title="Remove AOI"
                :icon="this.hoverHandler()"
                @mouseover="isHovered = true"
                @mouseleave="isHovered = false"
                @click="removeAOI()"
              ></b-icon>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>

    <b-button block variant="primary" @click="onSubmit" :disabled="submitBntDisabled">Next step</b-button>
    <b-button block variant="secondary" @click="onCancel">Cancel</b-button>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";
const gjv = require("geojson-validation");

export default {
  name: "CreateOrderStep2",
  components: {},
  computed: {
    drawnAOI() {
      return this.$store.getters.getDrawnAOI;
    },
    uploadedAOI() {
      return this.$store.getters.getUploadedAOI;
    },
    drawnAOICount() {
      if (this.drawnAOI) {
        return Object.keys(this.drawnAOI._layers).length;
      } else {
        return 0;
      }
    },
    isAOIUploaded() {
      return this.uploadedAOI != null;
    },
    aoiMode() {
      return this.$store.getters.getAoiMode;
    },
    submitBntDisabled() {
      if (this.aoiMode === 'MODE_DRAW') {
        return (this.drawnAOICount != 1 || this.aoiSizeWarning == true);
      } else if (this.aoiMode === 'MODE_UPLOAD') {
        return (!this.isAOIUploaded  || this.aoiSizeWarning == true);
      } else {
        return false
      }
    },
    aoiArea() {
      return this.$store.getters.getAOIArea;
    }
  },
  mounted() {
    eventBus.$emit('add-draw-control');
    this.$store.commit("setAoiMode", "MODE_DRAW");
  },
  data() {
    return {
      tabIndex: 0,
      file: null,
      isHovered: false,
      aoiSizeWarning: false,
      geoJSONWarning: false,
      ///aoiSizeMax: 100000000000,
      aoiSizeMax:  2000000000,
      aoiSizeCurrentAOI: 0,
    };
  },
  methods: {
    onSubmit() {
      this.$store.commit("setCreateStep", "step_3");
      this.$store.commit("setOrderStepTwo");
    },
    onCancel() {
      this.$store.commit("setCreateStep", "step_1");
      this.$store.commit("clearOrder");
    },
    uploadFile(e) {
      this.$store.commit('setUploadedAOI', null);

      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      
      let file = files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      
      var ref = this;
      reader.onload = function() {
        let file = reader.result;

        // Simple test if the uploaded file is a valid geojson file
        let isFileValid;
        try {
          if (gjv.isFeatureCollection(JSON.parse(file))) {
            isFileValid = true;
            ref.geoJSONWarning = false;
          } else {
            isFileValid = false;
            console.log("Error: The uploaded file is not valid");
          }
        }
        catch (e) {
          isFileValid = false;
          console.log("Error: The uploaded file is not a valid JSON file");
        }

        if (!isFileValid) {
          ref.geoJSONWarning = true;
          return;
        }

        ref.$store.commit('setUploadedAOI', JSON.parse(file));
      };

      reader.onerror = function() {
        console.log(reader.error);
        //this.file = null;
      };
    },
    hoverHandler() {
      if (this.isHovered === true) {
        return "trash-fill";
      } else if (this.isHovered === false) {
        return "trash";
      }
    },
    removeAOI() {
      this.file = null;
      this.$store.commit('setUploadedAOI', null);
      this.$store.commit('setAOIArea', null);
    },
    alertClosedClicked() {
      this.aoiSizeWarning = false;
    },
    getAreaInKM2(v) {
      const roundedAreaValue = parseInt(Math.round(v));
      const areaInKm2 = parseInt(roundedAreaValue / 1000000);
      
      return this.numberWithSpaces(areaInKm2);
    },
    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  beforeDestroy() {
    // eventBus.$emit('clear-map');
    eventBus.$emit('remove-draw-control');
  },
  watch: {
    tabIndex(v) {
      eventBus.$emit('clear-map');
      this.$store.commit("setAOIArea", null);
      if (v==0) {
        this.$store.commit("setAoiMode", "MODE_DRAW");
        eventBus.$emit('add-draw-control');
        this.$store.commit("setUploadedAOI", null);
        this.file = null;
      } else if (v==1) {
        this.$store.commit("setAoiMode", "MODE_UPLOAD");
        eventBus.$emit('remove-draw-control');
        this.$store.commit("setDrawnAOIs", null);
      }
    },
    aoiArea(v) {
      if (v > this.aoiSizeMax) {
        this.aoiSizeWarning = true;
        this.aoiSizeCurrentAOI = v;
      } else {
        this.aoiSizeWarning = false;
        this.aoiSizeCurrentAOI = null;
      }
    }
  }
};
</script>

<style>
#trash-icon:hover {
  cursor: pointer;
}

.custom-file-label:hover {
  cursor: pointer !important;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.aoi-info-label {
  font-style: italic;
}
</style>
