import Vue from "vue";
import Vuex from "vuex";
import axios_services from '@/axios/axios-services.js'

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    createStep: "step_1",
    createStepActiveArr: [true, false, false],
    createStepFinishedArr: [false, false, false],
    drawnAOIs: null,
    uploadedAOI: null,
    aoiMode: "",
    aoiActiveItem: null,
    aoiArea: null,
    order: {
      aoiName: "",
      aoiDescription: "",
      geometry: "",
      begin: "",
      end: ""
    },
    orders: [],
    ordersLoadingStatus: false,
    ordersLoadingError: null,
    userData: null
  },
  mutations: {
    setCreateStep(state, step) {
      state.createStep = step;
    },
    setCreateStepActiveArr(state, arr) {
      state.createStepActiveArr = arr;
    },
    setCreateStepFinishedArr(state, arr) {
      state.createStepFinishedArr = arr;
    },
    setDrawnAOIs(state, paylaod) {
      state.drawnAOIs = null;
      state.drawnAOIs = paylaod;
    },
    setUploadedAOI(state, paylaod) {
      state.uploadedAOI = paylaod;
    },
    setAOIActiveItem(state, paylaod) {
      state.aoiActiveItem = paylaod;
    },
    setAOIArea(state, paylaod) {
      state.aoiArea = paylaod;
    },
    setOrderStepOne(state, paylaod) {
      state.order.aoiName = paylaod.aoiName;
      state.order.aoiDescription = paylaod.aoiDescription;
    },
    setOrderStepTwo(state) {
      if (state.aoiMode === "MODE_DRAW") {
        state.order.geometry = state.drawnAOIs;
      } else if (state.aoiMode === "MODE_UPLOAD") {
        state.order.geometry = state.uploadedAOI;
      }
    },
    setOrderStepThree(state, paylaod) {
      state.order.begin = paylaod.begin;
      state.order.end = paylaod.end;
    },  
    setAoiMode(state, paylaod) {
      state.aoiMode = paylaod;
    },
    clearOrder(state) {
      state.order = {};
    },
    setOrders(state, paylaod) {
      state.orders = paylaod;
    },
    setOrdersLoadingStatus(state, paylaod) {
      state.ordersLoadingStatus = paylaod;
    },
    setOrdersLoadingError(state, paylaod) {
      state.ordersLoadingError = paylaod;
    },
    setUserData(state, payload) {
      state.userData = payload;
    }
  },
  actions: {
    fetchOrders(context) {
      context.commit('setOrdersLoadingStatus', true);
      context.commit('setOrdersLoadingError', null);
      
      return axios_services.get('orders/user')
        .then(response => {
          context.commit('setOrders', response.data.orders);
          context.commit('setOrdersLoadingStatus', false);
          context.commit('setOrdersLoadingError', null)
        })
        .catch((error) => {
          context.commit('setOrdersLoadingStatus', false);
          context.commit('setOrdersLoadingError', error);
          console.log("Error: Orders could not be loaded. Details: ", error)
        });
    },
  },
  getters: {
    getCreateStep(state) {
      return state.createStep;
    },
    getCreateStepActiveArr(state) {
      return state.createStepActiveArr;
    },
    getCreateStepFinishedArr(state) {
      return state.createStepFinishedArr;
    },
    getDrawnAOI(state) {
      return state.drawnAOIs;
    },
    getUploadedAOI(state) {
      return state.uploadedAOI;
    },
    getAOIActiveItem(state) {
      return state.aoiActiveItem;
    },
    getAOIArea(state) {
      return state.aoiArea;
    },
    getAoiMode(state) {
      return state.aoiMode;
    },
    getOrder(state) {
      return state.order;
    },
    getOrderList(state) {
      return state.orders;
    },
    getOrderLoadingStatus(state) {
      return state.ordersLoadingStatus;
    },
    getOrderLoadingError(state) {
      return state.ordersLoadingError;
    },
    getUserData(state) {
      return state.userData;
    }
  },
});

export default store;
