import axios from 'axios'

const axios_services = axios.create({
    baseURL: process.env.VUE_APP_WORLD_WATER_BACKEND_URL,
})

axios_services.interceptors.request.use(function (config) {
    let token = localStorage.getItem('accessToken');
    let key = 'Bearer ' + token;
    config.headers.Authorization = key;
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axios_services