<template>
  <div class="container" style="max-width: 980px">
    <h1 class="pt-5">About</h1>
    <p>
      The ESA-funded World Water project (<a href="https://worldwater.earth/" 
      target="_blank">https://worldwater.earth/</a>)
      develops novel multi-source EO tools for monitoring the seasonal and annual dynamics 
      of inland surface waters with the objective to empower countries and river basin 
      authorities with advanced EO technology to manage their water resources and 
      report on the global water agendas. Main objective is to develop a scientifically 
      robust method that exploits the full time series of Sentinel 1, Sentinel 2 and Landsat 
      satellite imagery to better capture the seasonal changes of surface waters in extent, 
      and to complement these observations with radar altimetry measurements of water levels 
      in order to derive the changes in lake volume and river discharge. 
      A Proof of Concept was conducted in 5 partner countries (Colombia, Mexico, Gabon, Zambia and Greenland).
    </p>

    <p>
      The service provides information on the occurrence of surface water and a variety 
      of water related indicators for a selected region and period of interest. 
      Based on the entire archive of freely available high-resolution Sentinel-1 
      radar and Sentinel-2 optical satellite imagery, the service allows to report and 
      monitor water dynamics over time as for the Sustainable Development Goals (SDGs), 
      especially the goal on ‘clean water for all’ (SDG 6) and the ‘climate action goal’ (SDG 13).
    </p>

    <p class="mt-4">
      <b>Important note:</b> The World Water mapping service is designed to work with 
      Sentinel-1 GRDH and Sentinel-2 L1C images, which means the earliest start date is 01.01.2017. 
    </p>

    <div class="row pt-5">
      <div class="col-sm align-self-center">
        <div>
          <img src="/img_esa.png" class="img-fluid esa-logo" />
        </div>
      </div>
      <div class="col-sm align-self-center">
        <img src="/WorldWater_big-1-1024x319.jpg" class="img-fluid" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.esa-logo {
  max-width: 60%;
}
</style>
