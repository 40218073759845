<!-- 
Note: In this version the update functionality is disabled. Therefore, some code is not required any longer. In order 
      to add the funtionality later on, the obsolete code visible.   
 -->
<template>
  <div id="profile-container" class="container">
    <b-overlay spinner-variant="secondary" rounded="sm">
      <div v-if="userData">
        <b-card title="Personal Information">
          <p class="text-muted card-info-text">
            This form shows your personal user data. (<span class="required"></span> Required fields)
          </p>
          <b-form>
            <!-- Mail information -->
            <b-form-group>
              <label class="label-style">E-mail address</label>
              <label class="required"></label>
              <b-form-input v-model="userData.email" disabled></b-form-input>
            </b-form-group>

            <!-- Name information -->
            <div class="row">
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">First Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="userData.given_name"
                    placeholder="First name ..."
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Last Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="userData.organisation_type"
                    placeholder="Last name ..."
                    autocomplete="family-name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Organization information -->
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Organization Name</label>
                  <label class="required"></label>
                  <b-form-input
                    v-model="userData.organisation_name"
                    placeholder="Organization name ..."
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Organization information -->
            <b-form-group>
              <label class="label-style">Organization Type</label>
              <label class="required"></label>
              <b-form-input
                v-model="userData.organisation_type"
                placeholder="Organization type ..."
                disabled
              ></b-form-input>
            </b-form-group>

            <!-- Address information 1 -->
            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Country</label>
                  <b-form-input
                    v-model="userData.country"
                    placeholder="Country ..."
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Address information -->
            <div class="row">
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Postal Code</label>
                  <b-form-input
                    v-model="userData.postal_code"
                    placeholder="Postal code ..."
                    disabled
                  >
                    ></b-form-input
                  >
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">City</label>
                  <b-form-input
                    v-model="userData.city"
                    placeholder="City ..."
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <!-- Address information -->
            <b-form-group>
              <label class="label-style">Street</label>
              <b-form-input
                v-model="userData.street_name"
                placeholder="Street ..."
                disabled
              ></b-form-input>
            </b-form-group>

            <div class="row mt-4">
              <div class="col text-right">
              </div>
            </div>

          </b-form>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: "ProfilePage",
  components: {
  },
  computed: {
    userData() {
      return this.$store.getters.getUserData;
    }
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style scoped>
#profile-container {
  margin-top: 50px;
}

.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 0px;
}

.required {
  margin-bottom: 0px !important;
}

.required:before {
  padding-left: 2px;
  content: "*";
  color: red;
  margin-bottom: 0px !important;
}
</style>
